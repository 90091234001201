<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">กราฟสัดส่วนคลังสินค้า</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="card">
              <div class="card-body p-0">
                <div class="row" v-if="isShow">
                  <div
                    class="col-12"
                    style="display: flex; justify-content: center"
                  >
                    <apexchart
                      type="donut"
                      width="500"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  props: {
    dialogChart2: Boolean,
    dataChart1: Object,
    dataChart2: Array,
    category: Array,
  },

  setup(props) {
    // const color = ref(props.color);

    // const series = [1622, 1322, 1622];
    const series = [];

    const chartOptions = {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: ["#E6E6FA"],
        },
        formatter: function (val, opts) {
          let newVal = opts.w.config.series[opts.seriesIndex];
          return newVal + " %";
        },
      },
      fill: {
        type: "gradient",
        // colors: ["#E91E63", "#7337EE", "#DCDCDC"],
        colors: [],
      },
      // colors: ["#E91E63", "#7337EE", "#DCDCDC"],
      colors: [],
      labels: [],

      legend: {
        show: true,

        // formatter: function (seriesName, opts) {
        //   return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
        // },

        // position: "bottom",
        itemMargin: {
          horizontal: 10,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },

      stroke: {
        // colors: ["#E91E63", "#7337EE", "#DCDCDC"],
        colors: [],
        width: 1,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    return {
      chartOptions,
      series,
    };
  },

  data: () => ({
    dataShow: [],

    isShow: false,

    isEmpty: false,

    colorsTheme: [
      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },
    ],
  }),

  watch: {
    dialogChart2(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        this.setDataChart2();
      }
    },
  },
  methods: {
    async setDataChart2() {
      if (this.dataChart2.length) {
        this.isShow = false;
        this.resetDataChart();

        this.dataChart2.forEach((element) => {
          this.category.forEach((element2) => {
            if (element.mainProductGroupId === element2.id) {
              const findIndex = this.category.findIndex(
                (data) => data.id === element2.id
              );

              this.category[findIndex] = {
                ...this.category[findIndex],
                qty: this.category[findIndex].qty
                  ? this.category[findIndex].qty // + element.product_bring_qty
                  : 0 + element.product_bring_qty,
              };
            }
          });
        });

        this.setDataToChart();
      } else {
        this.category.forEach((element2, index) => {
          this.category[index] = {
            ...this.category[index],
            qty: 0,
          };
        });

        this.setDataToChart();
      }
    },

    setDataToChart() {
      let newQty = 0;

      this.category.forEach((element, index) => {
        this.chartOptions.labels.push(element.main_product_group_name);
        // this.chartOptions.colors.push(
        //   "#" + Math.floor(Math.random() * 16777215).toString(16)
        // );

        this.chartOptions.colors.push("#" + this.colorsTheme[index].text);

        newQty = this.calculateSeries(element.qty ? element.qty : 0);

        this.series.push(parseFloat(newQty));
      });

      this.chartOptions.fill.colors = this.chartOptions.colors;
      this.chartOptions.stroke.colors = this.chartOptions.colors;

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    calculateSeries(qty) {
      if (qty === 0) {
        const newQty = 0;

        return newQty.toFixed(2);
      } else {
        const newQty =
          (parseFloat(qty) * 100) / parseFloat(this.dataChart1.productAmount);

        return newQty.toFixed(2);
      }
    },

    resetDataChart() {
      this.series = [];
      this.chartOptions.labels = [];
      this.chartOptions.colors = [];
      this.chartOptions.fill.colors = [];
      this.chartOptions.stroke.colors = [];
    },

    close() {
      this.$emit("closeDialogChart2");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
