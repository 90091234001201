<template>
  <div v-if="loading && stateLoading">
    <Loader />
  </div>
  <div class="card" v-if="!loading && !stateLoading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        Stock Movement
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary me-3"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
          @mouseenter="hoverABtn"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>
        <div v-if="hover4">
          <DialogExportFile
            :dialogExportFile="dialogExportFile"
            @closeDialogExportFile="closeDialogExportFile"
            @selectedTypeExportFile="selectedTypeExportFile"
          />
        </div>

        <button
          type="button"
          class="btn btn-sm btn-light-info me-3"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart1()"
          @click="openChart1()"
        >
          Min / Max
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light-primary me-3"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart2()"
          @click="openChart2()"
        >
          กราฟสัดส่วนคลังสินค้า
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart3()"
          @click="openChart3()"
        >
          กราฟมูลค่าคลังสินค้า
        </button>
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <!-- <div class="form-check form-switch mb-4">
          <input
            v-model="typeReport"
            class="form-check-input"
            type="checkbox"
            id="flexSwitchDefault"
          />
          <label class="form-check-label fw-boldest" for="flexSwitchDefault"
            >เลือกแบบรายเดือน</label
          >
        </div> -->
        <a-date-picker
          v-model:value="searchInput"
          :disabled-date="disabledDate"
          :picker="!typeReport ? 'date' : 'month'"
          style="border-radius: 6px; height: 38px; width: 100%"
          inputReadOnly
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="searchSnapshot()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table align-middle table-hover table-row-bordered table-row-dashed gy-5"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">ITEM</th>
              <th class="text-center">PRODUCT ID</th>
              <th class="text-center">PRODUCT CAT</th>
              <th class="text-center">PRICE</th>
              <th class="text-center">STATUS</th>
              <th class="text-center">QTY</th>
              <th class="text-center">MIN</th>
              <th class="text-center">MAX</th>
              <th class="text-center">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td class="fw-boldest text-center">{{ item.product_name }}</td>
              <td class="text-center">{{ item.product_code }}</td>
              <td>{{ item.mainProductGroup.main_product_group_name }}</td>
              <td class="text-end">
                {{ formatPrice(item.product_retail_price) }}
              </td>
              <td class="text-center">
                <button
                  style="padding: 5px; width: 100px; cursor: default"
                  :class="item.product_bring_qty > 0 ? class2 : class3"
                >
                  {{ item.product_bring_qty > 0 ? "In Stock" : "Out Of Stock" }}
                </button>
              </td>
              <td class="text-end">
                {{ formatThousand(item.product_bring_qty) }}
              </td>
              <td class="text-end">
                {{ formatThousand(item.product_min_stock) }}
              </td>
              <td class="text-end">
                {{ formatThousand(item.product_max_stock) }}
              </td>
              <td class="text-end">
                {{
                  formatPrice(
                    parseFloat(item.product_bring_qty) *
                      parseFloat(item.product_retail_price)
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr style="color: lightGrey" />

      <div class="row">
        <div class="col-12 col-sm-3 fw-boldest fs-4 mt-4">
          MIN รวม {{ formatThousand(sumPdMin) }} ชิ้น
        </div>
        <div class="col-12 col-sm-3 fw-boldest fs-4 mt-4 text-sm-center">
          MAX รวม {{ formatThousand(sumPdMax) }} ชิ้น
        </div>
        <div class="col-12 col-sm-3 fw-boldest fs-4 mt-4 text-sm-center">
          มีสินค้ารวม {{ formatThousand(sumPdAmount) }} ชิ้น
        </div>
        <div class="col-12 col-sm-3 fw-boldest fs-4 mt-4 text-sm-end">
          มูลค่า {{ formatThousand(sumPdPrice) }} บาท
        </div>
      </div>
    </div>

    <div v-if="hover1">
      <Chart1
        :dialogChart1="dialogChart1"
        :dataChart1="dataChart1"
        @closeDialogChart1="closeDialogChart1"
      />
    </div>

    <div v-if="hover2">
      <Chart2
        :dialogChart2="dialogChart2"
        :dataChart1="dataChart1"
        :dataChart2="dataChart2"
        :category="category"
        @closeDialogChart2="closeDialogChart2"
      />
    </div>

    <div v-if="hover3">
      <Chart3
        :dialogChart3="dialogChart3"
        :dataChart3="dataChart3"
        :searchInput1="searchInput1"
        :searchInput="searchInput"
        @closeDialogChart3="closeDialogChart3"
      />
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import whApi from "@/api/warehouse/";
import reportApi from "@/api/report/";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import Chart1 from "./components/chart1.vue";
import Chart2 from "./components/chart2.vue";
import Chart3 from "./components/chart3.vue";
import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { mapState } from "vuex";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
    Chart1,
    Chart2,
    Chart3,
  },
  setup() {
    document.title = "BELUCA | Stock Movement";

    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current >= dayjs().endOf("day").subtract(1, "day");
    };

    return {
      disabledDate,
    };
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    companyItems: [],

    typeReport: false,

    showing1: null,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchInput1: [],
    allData: [],

    pdItems: [],
    category: [],

    dateFormat: "YYYY/MM/DD",

    class1: "btn btn-sm btn-light-success fw-boldest fs-6 ",
    class2: "btn btn-sm btn-light-info fw-boldest fs-6 ",
    class3: "btn btn-sm btn-light-warning fw-boldest fs-6 ",

    sumPdAmount: 0,
    sumPdPrice: 0,
    sumPdMin: 0,
    sumPdMax: 0,

    dataChart1: {},
    dataChart2: [],
    dataChart3: [],

    dialogChart1: false,
    dialogChart2: false,
    dialogChart3: false,

    hover1: false,
    hover2: false,
    hover3: false,
    hover4: false,

    //export
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    loadingExport1: false,
  }),

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  async created() {
    await this.setDateToday();
    await this.getMainPd();
    await this.searchSnapshotChart3();
    await this.searchSnapshot();
  },

  methods: {
    setDateToday() {
      this.searchInput = dayjs(new Date()).subtract(1, "day");
      this.searchInput1[0] = dayjs(new Date()).subtract(12, "month");
      this.searchInput1[1] = dayjs(new Date());
    },

    async getMainPd(method) {
      this.loading = true;
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      let responseData = [];
      try {
        responseData = await whApi.mainProduct.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.category = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    async getPd() {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.product.getAll({
          is_cancel: "0",
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }

      if (responseData.response_status === "SUCCESS") {
        this.pdItems = responseData.data;
        this.dataChart2 = responseData.data;
        this.loading = false;

        this.sumAllAmount(responseData.data);
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    sumAllAmount(item) {
      this.sumPdAmount = item.reduce(
        (sum, data) => sum + data.product_bring_qty,
        0
      );

      this.sumPdPrice = item.reduce(
        (sum, data) =>
          sum +
          parseFloat(data.product_bring_qty) *
            parseFloat(data.product_retail_price),
        0
      );

      this.sumPdMin = item.reduce(
        (sum, data) => sum + data.product_min_stock,
        0
      );

      this.sumPdMax = item.reduce(
        (sum, data) => sum + data.product_max_stock,
        0
      );

      this.dataChart1 = {
        min: this.sumPdMin,
        max: this.sumPdMax,
        productAmount: this.sumPdAmount,
      };
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    // snapshot
    async searchSnapshot() {
      this.hover4 = false;
      this.isExcel = false;
      this.isPdf = false;

      this.$store.commit(Mutations.SET_LOADING, true);

      this.searchSnapshotChart3();

      let day = "";
      let month = "";
      if (!this.typeReport) {
        day = dayjs(this.searchInput).format("YYYY-MM-DD");
      } else {
        month = dayjs(this.searchInput).endOf("month").format("YYYY-MM-DD");
      }
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      this.loading = true;

      const responseSearch = await reportApi.product.searchWhSnapshot(
        {
          day: day,
          month: month,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
        }
      );

      if (responseSearch.response_status === "SUCCESS") {
        if (responseSearch.data.length) {
          this.pdItems = responseSearch.data[0].warehouseSnapshotItems;
          this.dataItemsShow = responseSearch.data[0].warehouseSnapshotItems;
          this.tableItems = responseSearch.data[0].warehouseSnapshotItems;
          this.dataChart2 = responseSearch.data[0].warehouseSnapshotItems;

          this.dataExportPdf = responseSearch.data[0].warehouseSnapshotItems;
          this.dataExportExcel = responseSearch.data[0].warehouseSnapshotItems;

          this.sumAllAmount(responseSearch.data[0].warehouseSnapshotItems);
        } else {
          this.pdItems = [];
          this.dataItemsShow = [];
          this.tableItems = [];
          this.sumAllAmount([]);
        }
        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
      } else {
        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    async searchSnapshotChart3() {
      this.$store.commit(Mutations.SET_LOADING, true);

      let day = "";
      day = dayjs(this.searchInput).format("YYYY-MM-DD");
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      this.loading = true;

      const responseSearch = await reportApi.product.searchTwelveWhSnapshot(
        {
          day: day,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
        }
      );

      if (responseSearch.response_status === "SUCCESS") {
        responseSearch.data.length
          ? (this.dataChart3 = responseSearch.data)
          : (this.dataChart3 = []);

        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
      } else {
        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    // chart 1
    openChart1() {
      this.dialogChart1 = true;
    },

    closeDialogChart1() {
      this.dialogChart1 = false;
    },

    hoverChart1() {
      this.hover1 = true;
      this.hover2 = false;
      this.hover3 = false;
      this.hover4 = false;
    },

    // chart 2
    openChart2() {
      this.dialogChart2 = true;
    },

    closeDialogChart2() {
      this.dialogChart2 = false;
    },

    hoverChart2() {
      this.hover1 = false;
      this.hover2 = true;
      this.hover3 = false;
      this.hover4 = false;
    },

    // chart 3
    openChart3() {
      this.dialogChart3 = true;
    },

    closeDialogChart3() {
      this.dialogChart3 = false;
    },

    hoverChart3() {
      this.hover1 = false;
      this.hover2 = false;
      this.hover3 = true;
      this.hover4 = false;
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },

    hoverABtn() {
      this.hover1 = false;
      this.hover2 = false;
      this.hover3 = false;
      this.hover4 = true;
    },
    //export file excel, pdf
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
