<template></template>

<script>
import * as XLSX from "xlsx";

import whApi from "@/api/warehouse/";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import numbro from "numbro";

export default {
  name: "app",
  props: {
    dataExportExcel: Object,
  },
  data() {
    return {
      jsonExport: [],

      mainProduct: [],
      subProduct: [],

      // loading: false
    };
  },

  async created() {
    await this.groupDateExport();
    // this.onExport();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");

        return oldDate;
      } else {
        return "-";
      }
    },

    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    groupDateExport() {
      this.dataExportExcel.forEach((element, index) => {
        this.jsonExport.push({
          ITEM: element.product_name,
          "PRODUCT ID": element.product_code,
          "PRODUCT CAT": element.mainProductGroup.main_product_group_name,
          PRICE: this.formatPrice(element.product_retail_price),
          STATUS: element.product_bring_qty > 0 ? "In Stock" : "Out Of Stock",
          QTY: this.formatThousand(element.product_bring_qty),
          MIN: this.formatThousand(element.product_min_stock),
          MAX: this.formatThousand(element.product_max_stock),
          TOTAL: this.formatPrice(
            parseFloat(element.product_bring_qty) *
              parseFloat(element.product_retail_price)
          ),
        });
      });

      this.onExport();
    },

    onExport() {
      const date = dayjs(new Date()).format("YYYY-MM-DD-HHMM");

      const dataWS = XLSX.utils.json_to_sheet(this.jsonExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "StockMovement1", true);
      XLSX.writeFile(wb, `StockMovement-${date}.xlsx`);
    },
  },
};
</script>
