<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">กราฟความเคลื่อนไหวคลังสินค้า</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="card">
              <div class="card-body p-0">
                <div class="mt-3 pb-4" v-if="isShow">
                  <apexchart
                    class="card-rounded-bottom h-125px"
                    type="area"
                    height="400"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import dayjs, { Dayjs } from "dayjs";

export default {
  props: {
    dialogChart3: Boolean,
    dataChart3: Array,
    searchInput1: Array,
    searchInput: Object,
  },

  setup() {
    const chartOptions = {
      fill: {
        colors: ["#8950FC"],
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: ["#8950FC"],
      },
      xaxis: {
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    const series = [
      {
        name: "มูลค่า",
        data: [],
      },
    ];

    return {
      chartOptions,
      series,
    };
  },

  data: () => ({
    isShow: false,

    newSeriesArr: [],
  }),

  watch: {
    dialogChart3(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        this.chartOptions.xaxis.categories = [];
        this.series = [
          {
            name: "มูลค่า",
            data: [],
          },
        ];
        this.handleDate();
      }
    },
  },
  methods: {
    handleDate() {
      this.isShow = false;

      const newDateStart = dayjs(this.searchInput.$d);
      const newDateEnd = dayjs(this.searchInput.$d)
        .endOf("month")
        .subtract(12, "month");

      const newFormatStart = dayjs(newDateStart.$d).format("YYYY-MM-DD");
      const newFormatEnd = dayjs(newDateEnd.$d).format("YYYY-MM-DD");

      let countMonth = dayjs(newFormatStart).diff(dayjs(newFormatEnd), "month");

      countMonth < 0 ? (countMonth *= -1) : countMonth;

      this.setXChart(newDateStart, newDateEnd, countMonth);
    },

    setXChart(newDateStart, newDateEnd, countMonth) {
      let newArrDate = [];

      for (let index = 0; index <= countMonth; index++) {
        newArrDate.push(
          dayjs(newDateStart)
            .subtract(index, "month")
            .format("YYYY-MMMM")
            .substring(5, 8)
        );
      }

      this.setSeries();

      newArrDate = newArrDate.reverse();

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: newArrDate,
          convertedCatToNumeric: false,
        },
      };

      setTimeout(() => {
        this.isShow = true;
      }, 1000);
    },

    setSeries() {
      console.log(this.dataChart3, 123);
      this.dataChart3.forEach((element) => {
        if (element.warehouseSnapshotItems) {
          console.log(element);
          const sumQty = element.warehouseSnapshotItems.reduce(
            (sum, data) =>
              sum + data.product_bring_qty * data.product_retail_price,
            0
          );
          this.series[0].data.push(sumQty);
        } else {
          this.series[0].data.push(0);
        }
      });
      this.series[0].data.reverse();
    },

    close() {
      this.$emit("closeDialogChart3");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
