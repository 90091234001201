<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../../assets/build/vfs_fonts";

import apiConfig from "../../../../config/api";
import whApi from "@/api/warehouse/";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import moment from "moment";
import numbro from "numbro";

export default {
  props: {
    dataExportPdf: Object,
  },
  data: () => ({
    jsonExport: [],

    newDataTable: [],

    user: "",
    numberToTh: "",
  }),
  async created() {
    await this.groupDateExport();
  },

  methods: {
    groupDateExport() {
      this.setDataToTable();
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");

        return oldDate;
      } else {
        return "-";
      }
    },

    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      const html = [
        {
          text: "Stock Movement",
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
        },

        // TABLE
        {
          table: {
            // width: "100%",
            widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*"],
            body: this.newDataTable,
          },
          margin: [0, 0, 0, 10],
        },
      ];

      const pdfData = {
        content: html,
        pageOrientation: "landscape",
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setDataToTable() {
      this.dataExportPdf.forEach((element, index) => {
        if (index === 0) {
          this.newDataTable.push(
            [
              {
                fontSize: 9,
                text: "ITEM",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "PRODUCT ID",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "PRODUCT CAT",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "PRICE",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "STATUS",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "QTY",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "MIN",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "MAX",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "TOTAL",
                alignment: "center",
              },
            ],
            [
              {
                text:
                  element.product_name.length > 15
                    ? element.product_name.substring(0, 15) + "..."
                    : element.product_name,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "left",
              },
              {
                text: element.product_code,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text:
                  element.mainProductGroup.main_product_group_name.length > 15
                    ? element.mainProductGroup.main_product_group_name.substring(
                        0,
                        15
                      ) + "..."
                    : element.mainProductGroup.main_product_group_name,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "left",
              },
              {
                text: this.formatPrice(element.product_retail_price),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text:
                  element.product_bring_qty > 0 ? "In Stock" : "Out Of Stock",
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: this.formatThousand(element.product_bring_qty),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: this.formatThousand(element.product_min_stock),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: this.formatThousand(element.product_max_stock),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: this.formatPrice(
                  parseFloat(element.product_bring_qty) *
                    parseFloat(element.product_retail_price)
                ),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
            ]
          );
        } else {
          this.newDataTable.push([
            {
              text:
                element.product_name.length > 15
                  ? element.product_name.substring(0, 15) + "..."
                  : element.product_name,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "left",
            },
            {
              text: element.product_code,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text:
                element.mainProductGroup.main_product_group_name.length > 15
                  ? element.mainProductGroup.main_product_group_name.substring(
                      0,
                      15
                    ) + "..."
                  : element.mainProductGroup.main_product_group_name,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "left",
            },
            {
              text: this.formatPrice(element.product_retail_price),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: element.product_bring_qty > 0 ? "In Stock" : "Out Of Stock",
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: this.formatThousand(element.product_bring_qty),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: this.formatThousand(element.product_min_stock),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: this.formatThousand(element.product_max_stock),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: this.formatPrice(
                parseFloat(element.product_bring_qty) *
                  parseFloat(element.product_retail_price)
              ),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
          ]);
        }
      });

      this.exports();
    },
  },
};
</script>
