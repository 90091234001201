<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">รายงานปริมาณสินค้า Min / Max</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="card">
              <div class="card-body p-0">
                <div class="mt-3 pb-4">
                  <div class="row pb-4">
                    <div class="col-12 text-center text-gray-600 fw-boldest">
                      Min
                    </div>
                  </div>

                  <div class="row" v-if="isShow">
                    <div class="col-2"></div>
                    <div class="col-8">
                      <apexchart
                        type="radialBar"
                        height="280"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                    <div class="col-2"></div>
                  </div>

                  <div class="row" style="margin-top: -15px">
                    <div class="col-4 text-end text-gray-600 fw-boldest">
                      Low
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4 text-gray-600 fw-boldest">Max</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  props: {
    dialogChart1: Boolean,
    dataChart1: Object,
  },

  setup(props) {
    // const color = ref(props.color);

    const series = [0];

    const chartOptions = {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#E91E63"],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#DCDCDC",
            strokeWidth: "97%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#7337EE",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
      stroke: {
        lineCap: "round",
      },
    };

    return {
      chartOptions,
      series,
    };
  },

  data: () => ({
    // minMaxAvg: 0
    isShow: false,
  }),

  watch: {
    dialogChart1(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        this.calculateMinMax();
      }
    },
  },
  methods: {
    calculateMinMax() {
      if (this.dataChart1.max != 0) {
        console.log(this.dataChart1);
        const avgMinMax =
          (parseFloat(this.dataChart1.productAmount) * 100) /
          parseFloat(this.dataChart1.max);
        this.series = [avgMinMax.toFixed(2)];
      }

      this.isShow = true;
    },
    close() {
      this.$emit("closeDialogChart1");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
